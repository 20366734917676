/* Place your extensions here to make migration to a new release easier. */

.layout-wrapper {
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #131a1c;
}

.porange {
  font-size: 2em;
  font-weight: 700;
  color: #e84e0f;
}
.pwhite {
  font-size: 2em;
  font-weight: 700;
  color: white;
}
.senden {
  color: #e85222;
  border-color: #fefefe;
  background-color: #fefefe;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  margin-right: 0px;
  padding: 12px 20px;
  padding-left: 20px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
  font-family: inherit;
  font-weight: bold;
  font-size: 12px;
  -webkit-appearance: none;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}

.p-button-text {
  color: #e85222;
  border-color: #fefefe;
  background-color: #fefefe;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  margin-right: 0px;
  padding: 16px 20px;
  padding-left: 20px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
  font-family: inherit;
  font-weight: bold;
  font-size: 12px;
  -webkit-appearance: none;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}

body .p-dialog .p-dialog-titlebar {
  background-color: #e84e0f;
}

.cbic {
  background-color: #0a0a0a;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
